exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-challenger-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/posts/challenger.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-challenger-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-game-dev-tools-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/posts/game-dev-tools.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-game-dev-tools-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-git-gud-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/posts/git-gud.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-git-gud-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-hello-world-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/posts/hello-world.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-hello-world-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-hosting-coding-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/posts/hosting-coding.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-hosting-coding-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-how-to-google-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/posts/how-to-google.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-how-to-google-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-learning-coding-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/posts/learning-coding.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-learning-coding-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-miscelanous-tools-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/posts/miscelanous-tools.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-miscelanous-tools-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-what-happens-when-you-google-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/posts/what-happens-when-you-google.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-what-happens-when-you-google-mdx" */)
}

